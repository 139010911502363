import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g transform="translate(21, 59) scale(0.33)">
          <path d="M 23.375 0 L 1.9375 -74.890625 L 19.234375 -74.890625 L 31.640625 -22.859375 L 32.25 -22.859375 L 45.9375 -74.890625 L 60.75 -74.890625 L 74.390625 -22.75 L 75.046875 -22.75 L 87.4375 -74.890625 L 104.734375 -74.890625 L 83.3125 0 L 67.875 0 L 53.609375 -48.96875 L 53.03125 -48.96875 L 38.796875 0 Z M 23.375 0" fill="currentColor" />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
