import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(29,66) scale(0.4)">
        <path
        d="M 23.375 0 L 1.9375 -74.890625 L 19.234375 -74.890625 L 31.640625 -22.859375 L 32.25 -22.859375 L 45.9375 -74.890625 L 60.75 -74.890625 L 74.390625 -22.75 L 75.046875 -22.75 L 87.4375 -74.890625 L 104.734375 -74.890625 L 83.3125 0 L 67.875 0 L 53.609375 -48.96875 L 53.03125 -48.96875 L 38.796875 0 Z M 23.375 0" fill="currentColor" />
        
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
